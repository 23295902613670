import { HOST, MOBI_ADMIN_HOST } from './environment';

export const API_URLS = {
  BASE_API: `${HOST}`,
  HOME_PLANNING: `${HOST}/planning`,
  HOME_PLANNING_DETAILS: `${HOST}/planning/shift`,
  TASKS: `${HOST}/employees/expiries`,
  DOCUMENTS: {
    DOCUMENTS_CONTENT: `${HOST}/employees/documents`,
    DOCUMENTS_SEARCH: `${HOST}/employees/documents-search`,
    DOWNLOAD: `${HOST}/employees/documents/download`
  },
  PREPAY: `${HOST}/employees/prepay/periods`,
  PREPAY_COUNTERS: `${HOST}/employees/prepay`,
  PREPAY_BALANCES: `${HOST}/employees/prepay/balance`,
  PREPAY_DESCRIPTION_COUNTERS: `${HOST}/employees/prepay/counters`,
  PREPAY_MOVEMENT_MODEL: `${HOST}/employees/prepay/counters/movement-model`,
  LINK: `${HOST}/employees/links`,
  MESSAGE: `${HOST}/employees/message`,
  MESSAGE_SEARCH: `${HOST}/employees/message/search`,
  MESSAGE_NOTIFICATION: `${HOST}/employees/message/count`,
  ENVIRONMENT: `${HOST}/employee/settings`,
  ABSENCE_HISTORY: `${HOST}/employees/absences`,
  ABSENCE_CALENDAR: `${HOST}/absence-request/planning`,
  ABSENCE_COUNTERS: `${HOST}/employees/counters/absence`,
  ABSENCE_REMAINING_SLOTS: `${HOST}/absence-request/remaining-places`,
  ABSENCE_CRUD: `${HOST}/absence-request`,
  SWAP: `${HOST}/swap-request`,
  SWAP_DETAILS: `${HOST}/swap-request/details`,
  CONTRACTUAL_PERIOD: `${HOST}/admin/contractual-period`,
  COUNTERS: `${HOST}/employees/counters`,
  LOGIN: `${HOST}/employees/login`,
  USER_DETAILS: `${HOST}/employees/user-details`,
  INFOS: `${HOST}/infos`,
  DEPARTMENTS: `${HOST}/planning/departments`,
  PUNCH_IN_OUT: `${HOST}/shifts`,
  VOLUNTEERING: `${HOST}/volunteering`,
  VOLUNTEERING_CALENDAR: `${HOST}/volunteering/planning`,
  MODIFY_PASSWORD: `${HOST}/employees/modify-password`,
  GET_NOTIFICATIONS: `${HOST}/employees/notification/choices`,
  SAVE_NOTIFICATION: `${HOST}/employees/notification/choices`,
  GET_CHARTER_INSTRUCTIONS: `${HOST}/planning/charter-instructions`,
  GET_ROSTER_INSTRUCTIONS: `${HOST}/planning/roster-instructions`,
  RESET_PASSWORD_OR_CREATE_ACCOUNT: `${HOST}/open/accounts/reset-password`,
  SEND_SMS_PASSWORD: `${HOST}/open/accounts/send-sms`,
  GET_SITES: `${HOST}/sites`,
  METADATA: `${HOST}/open/metatable`,
  SMS_PROVIDERS: `${HOST}/open/sms-providers`,
  HCAPTACHA_URL: 'https://hcaptcha.com/1/api.js'
};
